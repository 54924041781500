<template>
    <v-card
        class="mx-auto mb-12"
        style="background-color: unset;"
        elevation="0"
    >
        <h2 class="text-center">Datos Básicos</h2>
        <v-form
            class="px-5"
            ref="form"
            v-model="valid"
            lazy-validation
        >
            <v-autocomplete
                label="Aportante"
                v-model="contributor"
                required
                :items="contributors"
                item-text="name"
                item-value="_id"
                :rules="contributorRules"
            ></v-autocomplete>

            <v-autocomplete
                label="Departamento"
                v-model="state"
                required
                :items="states"
                item-text="name"
                item-value="_id"
                :rules="stateRules"
                @change="sortCities"
            ></v-autocomplete>

            <v-autocomplete
                label="Municipio"
                v-model="city"
                required
                :items="cities"
                item-text="name"
                item-value="_id"
                :rules="cityRules"
            ></v-autocomplete>

            <v-text-field
                v-model="address"
                :counter="addressMax"
                :maxlength="addressMax"
                :rules="addressRules"
                label="Dirección"
                required
            ></v-text-field>

            <v-text-field
                v-model="phone"
                :rules="phoneRules"
                type="number"
                label="Teléfono"
                required
            ></v-text-field>
        </v-form>
    </v-card>
</template>

<script>
import Storage from "@/modules/storage";
import { titleCase } from "@/modules/tools";

const store = new Storage();

export default {
    data() {
        return {
            mountedFinish: false,
            valid: false,
            contributor: '',
            contributorRules: [
                v => !!v || 'El Aportante es requerido',
            ],
            contributors: [],
            state: '',
            stateRules: [
                v => !!v || 'El Departamento es requerido',
            ],
            states: [],
            city: '',
            cityRules: [
                v => !!v || 'El Municipio es requerido',
            ],
            cities: [],
            address: '',
            addressMin: 0,
            addressMax: 1,
            addressRules: [
                v => !!v || 'La dirección es requerida',
                v => (v && v.length >= this.addressMin) || `La dirección debe tener al menos ${this.addressMin} letras`,
                v => (v && v.length <= this.addressMax) || `La dirección no debe superar las ${this.addressMax} letras`,
            ],
            phone: '',
            phoneMin: 0,
            phoneMax: 1,
            phoneRules: [
                v => !!v || 'El teléfono es requerido',
                v => (v && v >= this.phoneMin) || `El télefono debe ser un número superior a ${this.phoneMin}`,
                v => (v && v <= this.phoneMax) || `El télefono debe ser un número inferior a ${this.phoneMax}`,
            ],
        }
    },
    props: ['validate', 'default'],
    watch: {
        validate: function(value) {
            if (value) {
                this.$refs.form.validate();
            }
        },
        default: function(value) {
           if (value) {
               const checkMount = setInterval(async () => {
                   if (this.mountedFinish) {
                        this.contributor = value.contributor;
                        this.state = value.state;
                        this.city = value.city;
                        this.address = value.address;
                        this.phone = value.phone;
                        this.sortCities();
                        clearInterval(checkMount);
                   }
               }, 100);
            } 
        }
    },
    updated() {
        this.$emit('valid', {
            step: 1,
            valid: this.valid,
            data: {
                contributor: this.contributor,
                state: this.state,
                city: this.city,
                address: this.address,
                phone: parseInt(this.phone)
            }
        })
    },
    async mounted() {
        const schemaData = await store.getData("schema");
        const stateData = await store.getData("state");
        const contributorData = await store.getData("contributor");
        schemaData.forEach(schema => {
            if (schema.store === "branch") {
                this.addressMin = schema.data.address.min;
                this.addressMax = schema.data.address.max;
                this.phoneMin = schema.data.phone.min;
                this.phoneMax = schema.data.phone.max;
            }
        });
        stateData.sort(function(a, b) {
            return a.name.localeCompare(b.name);
        });
        stateData.forEach(state => {
            state.name = titleCase(state.name);
           this.states.push(state); 
        });
        contributorData.sort(function(a, b) {
            return a.name.localeCompare(b.name);
        });
        contributorData.forEach(contributor => {
            if (contributor.office === this.$route.params._id) {
                this.contributors.push(contributor); 
            }
        });
        this.valid = false;
        this.mountedFinish = true;
    },
    methods: {
        async sortCities() {
            const cityData = await store.getData("city");
            this.cities = [];
            cityData.sort(function(a, b) {
                return a.name.localeCompare(b.name);
            });
            cityData.forEach(city => {
                if (city.state === this.state) {
                    city.name = titleCase(city.name);
                    this.cities.push(city); 
                }
            });
        }
    },
}
</script>